<template>
  <div style="height: 100%;">
    <v-container class="container__work-rules-step">
      <v-form
        ref="form"
        v-model="isFormValid"
      >
        <BaseMasterFieldBlock
          title="Акционная скидка на товары"
          description="Настройте фиксированную скидку на акционные товары."
        >
          <template v-slot:input>
            <v-row align="baseline">
              <v-col cols="auto">
                <base-text-field
                      v-model.number="stepperData.offer_price_percent"
                      :rules="discountPercentRules"
                      placeholder="скидка"
                      suffix="%"
                      class="offer_price_percent-input"
                      maxlength="6"
                      outlined
                      type="number"
                      clearable
                      @blur="roundInputPercent"
                    />
              </v-col>
              <v-col cols="auto">
                действует
              </v-col>
              <v-col cols="auto">
                <v-select
                    v-model="stepperData.price_discount_mode"
                    class="offer_discount_multiple_value-input"
                    item-text="name"
                    item-value="value"
                    :items="selectsValue"
                    :rules="[
                      v => !!v || 'Выберите режим',
                    ]"
                    outlined
                  />
              </v-col>
              <v-col cols="auto" v-if="stepperData.price_discount_mode == 'multiple'">
                <base-text-field
                      suffix="единицу"
                      v-model="stepperData.price_discount_multiple_value"
                      class="ml-1 offer_price_percent-input"
                      :validate-on-blur="true"
                      :key-filter-regexp="/(\d|Delete|Backspace|Enter)/"
                      :rules="[
                        v => !!v || 'Обязательно для заполнения',
                        v => v <= 1000 || 'Не больше 1000',
                        v => v > 1 || 'Не меньше 1'
                      ]"
                    />
              </v-col>             
            </v-row>
          </template>
        </BaseMasterFieldBlock>
        <BaseMasterFieldBlock
          title="Правила работы акции"
          description="Выберите операции, с которым будет взаимодействовать акция. Во время акции, будут применяться новые правила и игонорироваться те, которые были настроены ранее на этапе формирования программы лояльности."
        >
          <template v-slot:input>
            <div
              v-for="operation in selectedOperations"
              :key="operation.id"
              class="operations"
            >
              <div class="operation__header">
                <p class="body-l-semibold neutral-900--text mb-0">
                  {{ operation.title }}
                </p>
                <v-btn
                  class="btn-close"
                  color="primary-100"
                  @click="deleteOperation(operation)"
                >
                  <v-icon
                    color="primary-base"
                    size="15"
                  >
                    $iconify_ion-close
                  </v-icon>
                </v-btn>
              </div>
              <div class="operation__body">
                <p class="body-title body-m-regular neutral-700--text mb-0">
                  Введите новое значение и срок действия
                  бонусов в период действия акции.
                </p>
                <base-text-field
                  v-model="operation.rules.newPercent"
                  v-mask="'#####'"
                  maxlength="5"
                  class="body-field"
                  :rules="percentRule"
                />
              </div>
              <div class="operation__footer">
                <div class="left-block">
                  <p class="body-m-semibold mb-0">
                    Текущие условия
                  </p>
                  <p class="body-s-regular neutral-700--text mb-0">
                    {{ getTypeText(operation) }}
                    {{ operation.rules.percent || 0 }}%
                  </p>
                </div>
                <v-icon
                  color="neutral-500"
                  size="21"
                >
                  $iconify_heroicons-outline-arrow-right
                </v-icon>
                <div class="right-block">
                  <p class="body-m-semibold mb-0">
                    Акционные условия
                  </p>
                  <p class="body-s-regular neutral-700--text mb-0">
                    {{ getTypeText(operation) }}:
                    {{ operation.rules.newPercent || 0 }}%
                  </p>
                </div>
              </div>
            </div>
            <v-btn
              v-if="!isAddOperation"
              class="btn__add-operation"
              color="primary-100"
              @click="isAddOperation = true"
            >
              <v-icon
                class="icon primary-base--text"
                size="21"
              >
                $iconify_feather-plus-circle
              </v-icon>
              <p class="body-m-semibold primary-base--text mb-0">
                Добавить операцию
              </p>
            </v-btn>
            <!-- <base-combobox
              v-else
              v-model="selectedBonusResources"
              class="combobox"
              not-found-placeholder="Операции не найдены!"
              :placeholder="'Введите название операции'"
              :items="compBonusResource"
              item-value="id"
              item-text="title"
              single
              @select="selectOperation"
            /> -->
            <v-autocomplete
              v-else
              v-model="selectedBonusResources"
              :items="compBonusResource"
              item-value="id"
              item-text="title"
              item-disabled="deleted_at"
              placeholder="Введите название операции"
              outlined
              clearable
              return-object
              @input="selectOperation"
            />
          </template>
        </BaseMasterFieldBlock>
      </v-form>
      <v-row>
        <v-col class="pa-0">
          <v-btn
            color="primary"
            class="master-next-btn"
            :disabled="!validData"
            @click="$emit('continue')"
          >
            <v-icon
              class="icon"
              color="neutral-100"
              size="21"
            >
              {{ isEdit ? '$iconify_ion-checkmark-circle-outline' : '$iconify_feather-plus-circle' }}
            </v-icon>
            <p class="body-m-semibold mb-0">
              {{ isEdit ? 'Сохранить' : 'Создать' }}
            </p>
          </v-btn>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  // other
  import { mapGetters, mapActions } from 'vuex'
  import { deepClone } from '@/utils'
  import { mask } from 'vue-the-mask'
  import Vue from 'vue'

  export default {
    directives: { mask },
    props: {
      stepperData: {
        type: Object,
        default: () => {
          return {}
        },
      },
    },
    inject: ['isEdit', 'isCopy'],
    data () {
      return {
        selectsValue: [
            { name: 'на все кол-во', value: 'all' },
            { name: 'на каждую', value: 'multiple' },
            { name: 'на 1 шт. с мин. ценой', value: 'one_quantity'}
        ],
        isFormValid: false,
        selectedBonusResources: null,
        selectedOperations: [],
        searchBonusResource: '',
        isAddOperation: false,
        percentRule: [
          v => !!String(v) || 'Введите процент акции',
          v => String(v).length < 6 || 'Поле не должно превышать 5 символов',
          v => isNaN(v) || Number(v) < 10001 || 'Процентная ставка не должна превышать 10000%',
        ],
        discountPercentRules: [
          (v) => (v === null || v === '' || +v >= 0) || 'Введите процент',
          (v) => (v <= 100) || 'Не более 100%',
        ],
      }
    },
    computed: {
      ...mapGetters({
        bonusResources: 'company/bonus_resources/buyBonusRes',
        programId: 'programId',
      }),
      validData () {
        let isValid = true
        if (!this.selectedOperations?.length) {
           if (this.stepperData.offer_price_percent && this.stepperData.offer_price_percent >= 0 && this.isFormValid) return true
           else return false
        } else {
          this.selectedOperations
            .map(br => br.rules.newPercent)
            .forEach(percent => {
              this.percentRule.forEach(callback => {
                const check = callback(percent)
                if (
                  typeof check !== 'boolean' ||
                  check !== true
                ) {
                  isValid = false
                }
              })
            })
        }

        return isValid
      },
      compBonusResource () {
        let bonusResources = deepClone(this.bonusResources)
        bonusResources = bonusResources.filter(br => {
          return !this.selectedOperations.some(sbr => br.id === sbr.id)
        })
        bonusResources = bonusResources.map(br => {
          br.rules.newPercent = ''
          return br
        })
        return bonusResources
      },
    },
    watch: {
      // programId () {
      //   this.selectedOperations = []
      //   this.fetchData()
      // },
      selectedOperations: {
        deep: true,
        handler (v) {
          const sbrs = []

          v.forEach(sbr => {
            sbrs.push({
              id: sbr.id,
              percent: +sbr.rules.newPercent,
            })
          })

          this.stepperData.program_bonus_res = sbrs
          // this.setStepperData('program_bonus_res', sbrs)
        },
      },
    },
    async mounted () {
      if (this.isEdit || this.isCopy) {
        this.selectedOperations = this.stepperData.program_bonus_res
      }
      Vue.set(this.stepperData, 'program_bonus_res', this.stepperData.program_bonus_res || [])
      // this.setStepperData('program_bonus_res', [])
      if (this.stepperData.price_discount_mode === null) {
        this.stepperData.price_discount_mode = 'all'
      }
      await this.fetchData()
    },
    methods: {
      ...mapActions({
        getBonusResource: 'company/bonus_resources/GetList',
      }),
      async fetchData () {
        await this.getBonusResource(this.stepperData.program_id || this.programId)
      },
      roundInputPercent() {
        if ((this.stepperData.offer_price_percent * 1000) % 1 !== 0) {
          this.stepperData.offer_price_percent = parseFloat(this.stepperData.offer_price_percent).toFixed(3)
        }
      },
      selectOperation () {
        console.log('selectOperation', this.selectedBonusResources)
        this.selectedOperations.push(Object.assign({}, this.selectedBonusResources))
        this.selectedBonusResources = null
        this.isAddOperation = false
      },
      deleteOperation (operation) {
        this.selectedOperations = this.selectedOperations
          .filter(so => so.id !== operation.id)
      },
      getTypeText (operation) {
        return operation.resource_type_enum === 'TYPE_TARGET'
          ? 'Списание'
          : 'Начисление'
      },
      // setStepperData (keys, values) {
      //   const stepperData = deepClone(this.stepperData)
      //   if (Array.isArray(keys)) {
      //     keys.forEach((key, idx) => {
      //       stepperData[key] = values[idx]
      //     })
      //   } else {
      //     stepperData[keys] = values
      //   }
      //   this.$emit('update:stepperData', stepperData)
      // },
    },
  }
</script>

<style lang="scss" scoped>
@import "@/styles/_typography";
@import "@/styles/vuetify-preset-plus/light_theme/_variables.sass";

.offer_price_percent-input {
  max-width: 10em;
}

.offer_discount_multiple_value-input {
  max-width: 12em;
}

.container__work-rules-step {
  .btn__add-operation {
    border-radius: 10px;
    .icon {
      margin-right: 8px;
    }
  }

  .combobox {
    max-width: 328px;
    & ::v-deep {
      .base-combobox__list {
        padding-bottom: 4px;
        padding-left: 16px;
        .base-combobox__list-scroll {
          padding-top: 4px;
          max-height: 212px;
          .list-item {
            margin-bottom: 12px;
            &:last-child {
              margin-bottom: 7px;
            }
            .list-item-title {
              padding: 0;
            }
          }
        }
      }
    }
  }

  .operations {
    position: relative;
    margin-bottom: 34px;
    margin-left: 22px;
    &::before {
      content: '';
      position: absolute;
      height: 100%;
      width: 2px;
      background: $primary-base;
      border-radius: 10px;
      left: -20px;
    }
    .operation__header {
      display: flex;
      align-items: center;
      .btn-close {
        margin-left: 12px;
        width: 31px;
        height: 31px;
        min-width: 0;
        padding: 0!important;
        border-radius: 50%;
      }
    }
    .operation__body {
      margin-top: 10px;
      .body-title {
        white-space: pre-line;
      }
      .body-field {
        margin-top: 12px!important;
        width: 154px;
      }
    }
    .operation__footer {
      margin-top: 20px;
      display: flex;
      padding-bottom: 8px;
      .left-block, .right-block {
        p:nth-child(2) {
          margin-top: 8px;
        }
      }
      .left-block {
        margin-right: 20px;
      }
      .right-block {
        margin-left: 20px;
      }
    }
  }
}
</style>
